import {
  ContactType,
  DocumentType,
  PriceCurrencyType,
  ProduitType,
  SubscriptionType,
} from '@innedit/innedit-type';
import { compact, uniq } from 'lodash';
import {
  ContactData,
  PriceData,
  ProduitData,
  SubscriptionData,
} from 'packages/innedit';
import React, { FC, useEffect, useState } from 'react';

import Tag from '~/components/Tag';
import { ListItemProps } from '~/containers/Espace/List/props';
import displayCurrency from '~/utils/displayCurrency';

import Item from './index';

const ListItemSubcription: FC<
  ListItemProps<SubscriptionType, SubscriptionData>
> = ({ docId, index, onClick, model }) => {
  const [doc, setDoc] = useState<DocumentType<SubscriptionType>>();
  const [contact, setContact] = useState<DocumentType<ContactType>>();
  const [products, setProducts] = useState<DocumentType<ProduitType>[]>();
  const [amount, setAmount] = useState<number>();
  const [currency, setCurrency] = useState<string>();
  const [interval, setInterval] = useState<string>();
  const [error, setError] = useState<string>();

  useEffect(() => {
    const unsub = model.watchById(docId, document => {
      if (setDoc) {
        setDoc(document);

        if (document) {
          const contactData = new ContactData({
            espaceId: model.espaceId,
          });

          contactData
            .findById(document.contactId)
            .then(docContact => {
              if (setContact) {
                setContact(docContact);
              }

              return true;
            })
            .catch(console.error);

          if (document.products && document.products.length > 0) {
            const produitData = new ProduitData({ espaceId: model.espaceId });
            const priceData = new PriceData({ espaceId: model.espaceId });
            const promisePrices: Promise<{
              amount: number;
              currency: PriceCurrencyType;
              interval: string;
              intervalCount: number;
              isRecurring: boolean;
            }>[] = [];
            const promiseProducts = document.products.map(
              ({ id, priceId, quantity }) => {
                promisePrices.push(
                  new Promise((resolve, reject) => {
                    priceData
                      .findById(priceId)
                      .then(price =>
                        resolve({
                          amount: (price.amount ?? 0) * quantity,
                          currency: price.currency ?? 'EUR',
                          interval: price.interval ?? 'month',
                          intervalCount: price.intervalCount ?? 1,
                          isRecurring: price.isRecurring ?? false,
                        }),
                      )
                      .catch(reject);
                  }),
                );

                return produitData.findById(id);
              },
            ) as Promise<DocumentType<ProduitType>>[];

            Promise.all(promiseProducts)
              .then(documents => {
                if (setProducts) {
                  setProducts(documents);
                }

                return true;
              })
              .catch(console.error);

            Promise.all(promisePrices)
              .then(prices => {
                let currencies: string[] = [];
                let isRecurrings: boolean[] = [];
                let intervals: string[] = [];
                let intervalCounts: number[] = [];
                const tmp = prices.reduce((acc, price) => {
                  currencies.push(price.currency);
                  isRecurrings.push(price.isRecurring);
                  intervals.push(price.interval);
                  intervalCounts.push(price.intervalCount);

                  return acc + price.amount;
                }, 0);

                currencies = uniq(compact(currencies));
                isRecurrings = uniq(compact(isRecurrings));
                intervals = uniq(compact(intervals));
                intervalCounts = uniq(compact(intervalCounts));
                if (1 === isRecurrings.length && isRecurrings[0]) {
                  if (1 === currencies.length && 1 === intervals.length) {
                    if (setAmount) {
                      setAmount(tmp);
                      setCurrency(currencies[0]);
                      setInterval(intervals[0]);
                    }
                  } else if (setError) {
                    setError('Non compatible entre eux');
                  }
                } else if (setError) {
                  setError('Non compatible abonnement');
                }

                return true;
              })
              .catch(console.error);
          }
        }
      }
    });

    return () => {
      if (unsub) {
        unsub();
      }
    };
  }, [docId, model]);

  if (!doc) {
    return null;
  }

  return (
    <Item
      description={`${amount} ${displayCurrency(currency)} ${interval}`}
      doc={doc}
      index={index}
      label={contact?.label ?? doc.contactId}
      onClick={onClick}
    >
      <div className="flex flex-1 items-center justify-between">
        <div className="flex flex-col items-start space-y-2">
          {products && products.length > 0 && (
            <div className="flex space-x-3 text-[10px]">
              {products.map(product => (
                <span
                  key={product.id}
                  className="rounded bg-light-500 px-1 py-0.5"
                >
                  {product.label}
                </span>
              ))}
            </div>
          )}
        </div>
        {error && <Tag state="danger">{error}</Tag>}
      </div>
    </Item>
  );
};

export default ListItemSubcription;
